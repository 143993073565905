<template>
  <div>
    <div v-if="!church.is_published" class="alert alert-primary" role="alert">
      Your church has been submitted for approval by AllSermons administration. 
      <br/>Please continue to fill out all applicable information to help with the approval process.
      <br/>Thank you for your patience.
    </div>

    <card :title="$t('church.info')">
      <form @submit.prevent="update" @keydown="form.onKeydown($event)">
        <alert-success :form="form" :message="$t('church.updated')" />    

        <church-form :form="form" :edit="true"></church-form>

        <!-- Submit Button -->
        <div class="form-group row">
          <div class="col-md-9 ml-md-auto">
            <v-button :loading="form.busy" type="success">
              {{ $t('update') }}
            </v-button>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
  import Form from 'vform'
  import ChurchForm from './form'
  import { mapGetters } from 'vuex'
  import { objectToFormData } from 'object-to-formdata'

  export default {
    scrollToTop: false,
    components: {
      'church-form': ChurchForm
    },
    data: () => ({
      form: new Form({
        name: '',
        phone: '',
        email: '',
        website: '',
        facebook: '',
        twitter: '',
        instagram: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        logoUrl: '',
        logo: null,
      })
    }),
    created () {
      this.refresh(this.church);
    },
    computed: mapGetters({
      church: 'account/church'
    }),
    methods: {
      refresh(church) {
        this.form.keys().forEach(key => {
          this.form[key] = church[key];
        });
      },
      async update() {
        const { data } = await this.form.submit('post', '/api/v1/user/me/church/' + this.church.id, {
          // Transform form data to FormData
          transformRequest: [function (data) {
            data._method = 'put';
            return objectToFormData(data)
          }]
        });

        let church = data.church;
        // Update the store
        this.$store.dispatch('account/updateChurch', { church: church })
        
        // Update the form
        this.refresh(church);
      }
    }
  }
</script>
