<template>
  <div>
    <!-- Name -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('church.name') }}</label>
      <div class="col-md-7">
        <input v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }" class="form-control" type="text" name="name">
        <has-error :form="form" field="name" />
      </div>
    </div>

    <!-- Phone -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('church.phone') }}</label>
      <div class="col-md-7">
        <input v-model="form.phone" :class="{ 'is-invalid': form.errors.has('phone') }" class="form-control" type="tel" name="phone">
        <has-error :form="form" field="phone" />
      </div>
    </div>

    <!-- Email -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('church.email') }}</label>
      <div class="col-md-7">
        <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control" type="email" name="email">
        <has-error :form="form" field="email" />
      </div>
    </div>

    <hr/>

    <!-- Socials -->
    <div class="form-group row" v-if="edit">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('church.website') }}</label>
      <div class="col-md-7">
        <div class="input-group mg-b-10">
          <div class="input-group-prepend">
            <span class="input-group-text"><FeatherIcon icon="globe"></FeatherIcon></span>
          </div>
          <input v-model="form.website" :class="{ 'is-invalid': form.errors.has('website') }" class="form-control" type="text" name="website">
        </div>
        <has-error :form="form" field="website" />
      </div>
    </div>

    <div class="form-group row" v-if="edit">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('church.facebook') }}</label>
      <div class="col-md-7">
        <div class="input-group mg-b-10">
          <div class="input-group-prepend">
            <span class="input-group-text"><FeatherIcon icon="facebook"></FeatherIcon></span>
          </div>
          <input v-model="form.facebook" :class="{ 'is-invalid': form.errors.has('facebook') }" class="form-control" type="text" name="facebook">
        </div>
        <has-error :form="form" field="facebook" />
        <a v-if="form.facebook" v-bind:href="facebookUrl" target="_blank">{{ facebookUrl }}</a>
      </div>
    </div>

    <div class="form-group row" v-if="edit">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('church.twitter') }}</label>
      <div class="col-md-7">
        <div class="input-group mg-b-10">
          <div class="input-group-prepend">
            <span class="input-group-text"><FeatherIcon icon="twitter"></FeatherIcon></span>
          </div>
          <input v-model="form.twitter" :class="{ 'is-invalid': form.errors.has('twitter') }" class="form-control" type="text" name="twitter">
        </div>
        <has-error :form="form" field="twitter" />
        <a v-if="form.twitter" v-bind:href="twitterUrl" target="_blank">{{ twitterUrl }}</a>
      </div>
    </div>

    <div class="form-group row" v-if="edit">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('church.instagram') }}</label>
      <div class="col-md-7">
        <div class="input-group mg-b-10">
          <div class="input-group-prepend">
            <span class="input-group-text"><FeatherIcon icon="instagram"></FeatherIcon></span>
          </div>
          <input v-model="form.instagram" :class="{ 'is-invalid': form.errors.has('instagram') }" class="form-control" type="text" name="instagram">
        </div>
        <has-error :form="form" field="instagram" />
        <a v-if="form.instagram" v-bind:href="instagramUrl" target="_blank">{{ instagramUrl }}</a>
      </div>
    </div>

    <hr/>

    <!-- Address -->
    <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('locations.address') }}</label>
        <div class="col-md-7">
            <input v-model="form.address" :class="{ 'is-invalid': form.errors.has('address') }" class="form-control"
                    type="text" name="address">
            <has-error :form="form" field="address"/>
        </div>
    </div>

    <!-- Address2 -->
    <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('locations.address2') }}</label>
        <div class="col-md-7">
            <input v-model="form.address2" :class="{ 'is-invalid': form.errors.has('address2') }"
                    class="form-control" type="text" name="address2">
            <has-error :form="form" field="address2"/>
        </div>
    </div>

    <!-- City -->
    <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('locations.city') }}</label>
        <div class="col-md-7">
            <input v-model="form.city" :class="{ 'is-invalid': form.errors.has('city') }" class="form-control"
                    type="text" name="city">
            <has-error :form="form" field="city"/>
        </div>
    </div>

    <!-- State -->
    <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('locations.state') }}</label>
        <div class="col-md-7">
            <input v-model="form.state" :class="{ 'is-invalid': form.errors.has('state') }" class="form-control"
                    type="text" name="state">
            <has-error :form="form" field="state"/>
        </div>
    </div>

    <!-- Zip -->
    <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('locations.zip') }}</label>
        <div class="col-md-7">
            <input v-model="form.zip" :class="{ 'is-invalid': form.errors.has('zip') }" class="form-control"
                    type="text" name="zip">
            <has-error :form="form" field="zip"/>
        </div>
    </div>

    <hr/>

    <!-- Logo -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('church.logo') }}</label>
      <div class="col-md-7">
        <img id="logo" v-bind:src="form.logoUrl" v-if="form.logoUrl" class="mg-b-15"/>
        <div class="custom-file">
          <input v-on:change="changeLogo" :class="{ 'is-invalid': form.errors.has('logo') }" class="custom-file-input" type="file" name="logo" id="logo">
          <has-error :form="form" field="logo" />
          <label class="custom-file-label" for="logo">{{ logoLabel }}</label>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import FeatherIcon from '~/components/FeatherIcon'

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    title: window.config.appName,
  }),
  components: {
    FeatherIcon
  },
  computed: {
    logoLabel: function() {
      return this.form.logo ? this.form.logo.name : 'Choose file';
    },
    facebookUrl: function() {
      return 'https://facebook.com/' + this.form.facebook;
    }, 
    twitterUrl: function() {
      return 'https://twitter.com/' + this.form.twitter;
    }, 
    instagramUrl: function() {
      return 'https://instagram.com/' + this.form.instagram;
    }, 
  },
  methods: {
    changeLogo(e) {
      const file = e.target.files[0];
      this.form.logo = file;
    }
  },
  watch: {
    'form.facebook' (n) {
      this.form.facebook = n.replace(/(^\w+:|^)\/\/(www.)*facebook.com\//, '').replace(/\/$/, '');
    },
    'form.twitter' (n) {
      this.form.twitter = n.replace(/(^\w+:|^)\/\/(www.)*twitter.com\//, '').replace(/\/$/, '');
    },
    'form.instagram' (n) {
      this.form.instagram = n.replace(/(^\w+:|^)\/\/(www.)*instagram.com\//, '').replace(/\/$/, '');
    }
  },
}
</script>
<style scope="scss">
img#logo {
  max-width: 100%;
  max-height: 15rem;
}
.input-group-prepend .input-group-text svg {
  width: 1rem !important;
  height: 1rem !important;
}
</style>